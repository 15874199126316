import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LaunchPadAddModal from "../partials/LaunchpadAddModal";
// import StakeUpdateModal from "../partials/StakeUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import backurl  from "../../lib/config";

class LaunchPad extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "Pool",
                className: "id",
                align: "left",
                  cell: record =>  {
                    return    (
                       <p>{record.pid + 1}</p>
                      )}
            },
          
         {
                key:"PresaleAddress",
                text: "Presale Address",
                className: "PresaleAddress",
                align: "left",
               
            },
          
           
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log("record.PresaleAddress",record.PresaleAddress);
                    return (
                        <Fragment>
                        <Link to={`/ManageSale/${record.PresaleAddress}`}
                        
                                //data-toggle="modal"
                                //data-target="#update-stake-modal"
                                className="btn btn-primary btn-sm"
                                //onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </Link>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Pools",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "<<",
                    previous: "<",
                    next: ">",
                    last: ">>"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
            PriceToken:0,
            TokenAddress:"",
            TokenName:0,
            TokenDecimal:0,
            MinimumContribution:0,
            MaximumContribution:0,
            NumberDaySale: 0,
            DepositeToken:0,
            HardCap:0,
            PresaleAddress:0,
            WhiteList:[{ user: "", bnbvalue: "" }],
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(backurl+"/api/LaunchPad-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(backurl+"/api/LaunchPad-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <LaunchPadAddModal/>
                    {/* <StakeUpdateModal record={this.state.currentRecord}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                           <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-launchpad-modal"><FontAwesomeIcon icon={faPlus}/> Add LaunchPad</button>
                            <h1 className="mt-2 text-primary">LaunchPad</h1>
                            <div className="responsive-table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            /></div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

LaunchPad.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(LaunchPad);
